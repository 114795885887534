var init, readersList;

import Turbolinks from 'turbolinks';

import $ from 'jquery';

window.jQuery = $;

window.$ = $;

import ReadersOverview from './readers/overview';


// Start.

Turbolinks.start();

$(document).on('ready, turbolinks:load', function() {
  init();
});


// Initialize.

init = function() {
  return readersList();
};


// Readers: List

readersList = function() {
  var r;
  if ($('input.location').data('section') !== 'readers-overview') {
    return;
  }
  r = new ReadersOverview();
  r.init();
};
