var ReadersOverview;

import $ from 'jquery';

window.jQuery = $;

window.$ = $;


// Readers: Lost.

export default ReadersOverview = class ReadersOverview {
  constructor() {}

  
  // Initialize.

  init() {
    this.drawList();
  }

  
  // Draw list.

  drawList() {
    var r;
    r = this;
    $.ajax({
      type: 'GET',
      url: "/readers/list",
      timeout: 10000,
      statusCode: {
        200: function(data) {
          // Get our primary reader
          $.each(data.Reader, function(k, reader) {
            if (reader.Primary === true) {
              return $('div#readers').append(r.renderReader(reader));
            }
          });
          // Display all readers.
          return $.each(data.Reader, function(k, reader) {
            if (reader.Primary === false) {
              return $('div#readers').append(r.renderReader(reader));
            }
          });
        },
        500: function() {
          return console.log("Error..");
        }
      }
    });
  }

  
  // Render Reader

  renderReader(r) {
    return `<div class='row' style='margin-bottom: 5px;'>
  <div class='col-4' style=''>
    <img class='border border-white img-fluid w-10' src='${r.Picture}'/>
  </div>
  <div class='col'>
    <span class='name'>${r.Name}</span>
    <p class='description'>
      ${$.trim(r.Description).substring(0, 150).trim(this) + '...'}
    </p>
  </div>
</div>
<div class='row divider' style='margin-bottom: 10px;'>
  <div class='col-4' style=''>
    <span class='text-uppercase status'>${r.Status}</span>
  </div>
  <div class='col'>
    <span class='pin'>PIN: ${r.Pin}</span>
  </div>
</div>`;
  }

};
